import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import AppointmentSystem from '@/app/components/appointmentsystem/AppointmentSystem';

const Angebot = ({ title, image, description, pricedescription, durationdescription, appointmentType, buchbar }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFormSubmit = () => {
        setOpen(false);
    };

    return (
        <CardContent>
            <Grid container spacing={4} mb={10} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className="offerHeader" gutterBottom>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardMedia
                            component="img"
                            image={image}
                            alt={title}
                            sx={{
                                height: 200,
                                objectFit: 'cover'
                            }}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" className='offerBody' sx={{
                        '@media (max-width: 600px)': {
                            display: 'none',
                        },
                    }} paragraph>
                        {description}
                    </Typography>
                    <Typography variant="body2" className='pricedescription' paragraph>
                        {pricedescription}
                    </Typography>
                    <Typography variant="caption" className='durationdescription' paragraph>
                        {durationdescription}
                    </Typography>
                    {buchbar && 
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Jetzt Buchen
                    </Button>}
                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle id="modal-title">Buchung</DialogTitle>
                <DialogContent>
                    <AppointmentSystem initialAppointmentType={appointmentType} onFormSubmit={handleFormSubmit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </CardContent>
    );
};

export default Angebot;
