'use client'

import React from "react";
import { motion } from 'framer-motion';
import { Container, Typography, Box, Grid } from '@mui/material';
import ModalImage from "react-modal-image";

export const PortfolioSection = ({ data }) => {
    return (
        <motion.section
            className="section-large"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            id="portfolio-section"
        >
            <div className="section-header">
                <div className="section-title">
                    <Typography variant="h3" component="h2" gutterBottom align="center">
                        {data.title}
                    </Typography>
                </div>
            </div>
            <Container maxWidth="lg">

                <div className="section-body">
                    <Grid container spacing={4} alignItems="center" justifyContent={data.pictures.length === 1 ? 'center' : 'flex-start'}>

                        {data.pictures && data.pictures.map((bild, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4}>
                                <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', height: 200, overflow: 'hidden' }}>
                                <Box
    sx={{
      height: '100%',
      width: '100%',
      '& img': {
        objectFit: 'cover', // Crops the image inside the box
        height: '100%',
        width: '100%',
      },
    }}
  >
    <ModalImage
      small={bild.image}
      large={bild.image}
      alt={bild.title}
      hideDownload={false}
      hideZoom={false}
    />
  </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Container>
        </motion.section>
    );
};
