'use client'

import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

const NotesComponent = ({ onNotesChange }) => {
  const handleNotesChange = (event) => {
    const notes = event.target.value;
    onNotesChange(notes);
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom align="justify">
        Zusatzinfos
      </Typography>
      <TextField
        fullWidth
        label="Zusätzliche Infos für Gesundheit ganzheitlich"
        multiline
        rows={4}
        onChange={handleNotesChange}
        variant="outlined"
      />
    </Box>
  );
};

export default NotesComponent;
