'use client'

import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const AppointmentTypeSelectionComponent = ({ appointmentTypes, onTypeSelect, initialAppointmentType }) => {
  const [selectedType, setSelectedType] = useState("null");

  useEffect(() => {
    if (appointmentTypes.length > 0) {
      const defaultAppointment = appointmentTypes.find(x => x.name === initialAppointmentType);
      const defaultId = defaultAppointment ? defaultAppointment.id : "null";
      setSelectedType(defaultId);
      onTypeSelect(defaultId);
    }
  }, [appointmentTypes, initialAppointmentType]);

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);  // Update the selected type
    onTypeSelect(selectedType);
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom align="justify">
        Art der Behandlung
      </Typography>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}
          required>
        <InputLabel id="appointment-type-label">Behandlungsart</InputLabel>
        <Select
          labelId="appointment-type-label"
          id="appointment-type"
          required
          onChange={handleTypeChange}
          value={selectedType}  // Bind the value to selectedType state
          label="Appointment Type"
        >
          <MenuItem value="null" disabled>Wähle eine Behandlungsart</MenuItem>
          {appointmentTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AppointmentTypeSelectionComponent;
