'use client'

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AppointmentTypeSelectionComponent from '@/app/components/appointmentsystem/AppointmentTypeSelectionComponent';
import NotesComponent from '@/app/components/appointmentsystem/NotesComponent';
import CustomerComponent from '@/app/components/appointmentsystem/CustomerComponent';
import { useForm } from 'react-hook-form';
import { Typography, Button, Box, CircularProgress, Alert, Link, Checkbox, FormControlLabel } from '@mui/material';
import DateTimeSelectionComponent from '@/app/components/appointmentsystem/DateTimeSelectionComponent';

const AppointmentSystem = ({ initialAppointmentType, onFormSubmit }) => {
    const { register, handleSubmit, setValue, formState: { isValid } } = useForm({ mode: 'onChange' });
    const [appointmentType, setAppointmentType] = useState('');
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [feedback, setFeedback] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    useEffect(() => {
        const fetchAppointmentTypes = async () => {
            const response = await axios.get('/api/appointmenttypes');
            setAppointmentTypes(response.data);
        };

        fetchAppointmentTypes();
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=6LfAIy8hAAAAAKKMl1__D4RiikDvrD6Uwczt3h_a`;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        setIsButtonDisabled(!isValid || isLoading || !agreedToTerms);
    }, [isValid, isLoading, agreedToTerms]);

    const handleTypeSelect = (type) => {        
        setAppointmentType(type);
        setValue('appointment_type_id', type);
    };
    const handleDateSelect = (date) => {        
        setValue('date', date);
    };
    const handleTimeSelect = (time) => {        
        setValue('time_slot', time);
    };

    const handleNotesChange = (notes) => {
        setValue('notes', notes);
    };

    const handleBookingSubmit = async (data) => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(async () => {
                try {
                    const token = await window.grecaptcha.execute('6LfAIy8hAAAAAKKMl1__D4RiikDvrD6Uwczt3h_a', { action: 'submit' });
                    await submitFormWithToken(data, token);
                } catch (error) {
                    setFeedback('reCAPTCHA konnte nicht ausgeführt werden. Bitte versuchen Sie es erneut.');
                    setFeedbackType('error');
                }
            });
        } else {
            setFeedback('reCAPTCHA ist nicht geladen. Bitte überprüfen Sie Ihre Internetverbindung.');
            setFeedbackType('error');
        }
    };

    const submitFormWithToken = async (data, token) => {
        setIsLoading(true); // Start loading
        const formDataWithToken = { ...data, 'g-recaptcha-response': token };
        try {
            const response = await axios.post('/api/appointments', formDataWithToken);

            if (response.status === 201) {
                setFeedback('Buchung erfolgreich übermittelt.');
                setFeedbackType('success');
                setTimeout(() => setFeedback(''), 5000);
                onFormSubmit();
            } else {
                const errorData = response.data;
                setFeedback(`Es gab ein Problem mit dem Senden Ihrer Buchung: ${errorData.message}`);
                setFeedbackType('error');
                setTimeout(() => setFeedback(''), 5000);
            }
        } catch (error) {
            setFeedback('Serverfehler beim Senden Ihrer Buchung. Bitte versuchen Sie es später erneut.');
            setFeedbackType('error');
        } finally {
            setIsButtonDisabled(true);
            setIsLoading(false);
        }
    };

    return (
        <Box>
            <Typography variant="h3" component="h2" gutterBottom align="justify">
                Eine Behandlung Buchen
            </Typography>
            <Box component="form" onSubmit={handleSubmit(handleBookingSubmit)}>
                <AppointmentTypeSelectionComponent
                    appointmentTypes={appointmentTypes}
                    onTypeSelect={handleTypeSelect}
                    initialAppointmentType={initialAppointmentType} // Pass the initial type here
                />
                <DateTimeSelectionComponent onDateSelect={handleDateSelect} onTimeSelect={handleTimeSelect} appointmentType={appointmentType} />
                <CustomerComponent register={register} />
                <NotesComponent onNotesChange={handleNotesChange} />
                <FormControlLabel 
                    control={<Checkbox checked={agreedToTerms} onChange={(e) => setAgreedToTerms(e.target.checked)} />}
                    label={
                        <Typography variant="body2">
                            Mit der Buchung akzeptiere ich die{' '}
                            <Link href="/agb" target="_blank" rel="noopener noreferrer" underline="hover">AGBs</Link>.
                        </Typography>
                    }
                />
                <Box sx={{ mt: 2, textAlign: 'justify' }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isButtonDisabled || isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Buchen'}
                    </Button>

                    <Typography variant="caption" sx={{ mt: 2, display: 'block' }}>
                        Diese Seite ist durch reCAPTCHA geschützt und es gelten die
                        <Link className="external-link" href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" underline="hover"> Datenschutzrichtlinien </Link>
                        und
                        <Link className="external-link" href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer" underline="hover"> Nutzungsbedingungen </Link>
                        von Google.
                    </Typography>

                    {feedback && (
                        <Alert severity={feedbackType} sx={{ mt: 3 }}>
                            {feedback}
                        </Alert>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AppointmentSystem;
