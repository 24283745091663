'use client'

import React from "react";
import { motion } from 'framer-motion';
import { Typography, Grid, CardMedia, Container, Box } from '@mui/material';
import Link from "next/link";

export const UeberMichSection = ({ data }) => {

    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            id="uebermich-section"
            sx={{ py: 4 }}
        >
            <Container maxWidth="lg">
                <Box sx={{ mb: 4, textAlign: 'center' }}>
                    <Typography variant="h3" component="h2" gutterBottom>
                        {data.title}
                    </Typography>
                </Box>
                <Grid container spacing={4} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} display="flex" justifyContent="center">
                        <CardMedia
                            component="img"
                            image={data.image}
                            alt="Über mich"
                            sx={{
                                width: '100%',
                                maxWidth: { md: '500px' },
                                height: 'auto',
                                borderRadius: 2,
                                boxShadow: 1
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="text.secondary" variant="body1">
                            {data.description}
                        </Typography>
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h6" component="h3" gutterBottom>
                                Ausbildung
                            </Typography>
                            {data.skills.map((skill, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" component="h4" gutterBottom>
                                        {skill.title}
                                    </Typography>
                                    {skill.subskills.map((subskill, subIndex) => (
                                        <Typography key={subIndex} variant="caption" color="text.secondary" paragraph className="subskill">
                                            {subskill}
                                        </Typography>
                                    ))}
                                </Box>
                            ))}
                            <Link href={data.link}>
                            <Typography>
                                {data.linkname}
                            </Typography>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </motion.section>
    );
};
