'use client'

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Select, MenuItem, FormControl, Grid, InputLabel } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';

const DateTimeSelectionComponent = ({ appointmentType, onDateSelect, onTimeSelect }) => {
  const { reset, control } = useForm();
  const [sortedTimeSlots, setSortedTimeSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);

  useEffect(() => {
    // Reset form fields and local state when appointment type changes
    reset({
      date: '',
      time_slot: ''
    });
    onDateSelect(null);
    onTimeSelect(null);
    setSortedTimeSlots([]);
    setAvailableDates([]);
    setAvailableSlots([]);

    const fetchDisabledDates = async () => {
      try {
        const response = await axios.get(`/api/availableSlots?appointmentType=${appointmentType}`);
        setAvailableSlots(response.data);
        const distinctDates = Array.from(new Set(response.data.map(slot => slot.date)));
        setAvailableDates(distinctDates);
      } catch (error) {
        console.error('Error fetching closed days:', error);
      }
    };

    fetchDisabledDates();
  }, [appointmentType, reset]);

  const handleDateChange = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    onDateSelect(formattedDate)

    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };

    const timeslots = availableSlots
      .filter(slot => slot.date === formattedDate)
      .map(slot => slot.slot)
      .sort((a, b) => timeToMinutes(a) - timeToMinutes(b));

    setSortedTimeSlots(timeslots);
  };

  const handleTimeChange = (event) => {
    onTimeSelect(event.target.value);
  };

  const isDateDisabled = (date) => {
    const dateString = date.format('YYYY-MM-DD');
    return !availableDates.includes(dateString);
  };

  const tomorrow = dayjs().add(1, 'day');
  const maxDate = dayjs().add(60, 'day');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom align="justify">
          Wähle ein Datum & eine Zeit
        </Typography>
        <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    className="datePicker"
                    fullWidth
                    label="Datum"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      handleDateChange(date);
                      field.onChange(date);
                    }}
                    shouldDisableDate={isDateDisabled}
                    minDate={tomorrow}
                    maxDate={maxDate}
                    disablePast
                    format="DD.MM.YYYY"
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="time-slot-label">Zeit</InputLabel>
                <Controller
                  name="time_slot"
                  control={control}
                  render={({ field }) => (
                    <Select
                      labelId="time-slot-label"
                      id="time-slot"
                      value={field.value || ''}
                      onChange={(event) => {
                        handleTimeChange(event);
                        field.onChange(event);
                      }}
                      required
                      label="Zeit"
                    >
                      {sortedTimeSlots.map((slot) => (
                        <MenuItem key={slot} value={slot}>{slot}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DateTimeSelectionComponent;
