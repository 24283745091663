'use client'

import React from 'react';
import { Box, Typography, TextField, Grid } from '@mui/material';

const CustomerComponent = ({ register, errors = {} }) => {
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom align="justify">
        Kundeninformationen
      </Typography>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Vorname"
              margin="normal"
              {...register('customer_firstname', { required: 'Vorname ist erforderlich' })}
              error={!!errors.customer_firstname}
              helperText={errors.customer_firstname?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nachname"
              margin="normal"
              {...register('customer_lastname', { required: 'Nachname ist erforderlich' })}
              error={!!errors.customer_lastname}
              helperText={errors.customer_lastname?.message}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Strasse & Nr"
              margin="normal"
              {...register('customer_street', { required: 'Strasse & Nr ist erforderlich' })}
              error={!!errors.customer_street}
              helperText={errors.customer_street?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Postleitzahl"
              margin="normal"
              {...register('customer_postalcode', { required: 'PLZ ist erforderlich' })}
              error={!!errors.customer_postalcode}
              helperText={errors.customer_postalcode?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Wohnort"
              margin="normal"
              {...register('customer_city', { required: 'Wohnort ist erforderlich' })}
              error={!!errors.customer_city}
              helperText={errors.customer_city?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="E-Mail"
              type="email"
              margin="normal"
              {...register('customer_email', {
                required: 'Email ist erforderlich',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Ungültige Email-Adresse'
                }
              })}
              error={!!errors.customer_email}
              helperText={errors.customer_email?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Ihre Telefonnummer"
              type="tel"
              margin="normal"
              {...register('customer_phone', {
                required: 'Telefonnummer ist erforderlich',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Ungültige Telefonnummer'
                }
              })}
              error={!!errors.customer_phone}
              helperText={errors.customer_phone?.message}
              required
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerComponent;
