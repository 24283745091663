'use client'

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Grid, Container, Typography } from '@mui/material';
import Image from "next/image";
import Angebot from "./subcomponents/Angebot";

export const AngebotSection = ({ data }) => {
    const [appointmentTypes, setAppointmentTypes] = useState(null);

    useEffect(() => {
        const fetchAppointmentTypes = async () => {
            try {
                const response = await axios.get('/api/appointmenttypes');
                setAppointmentTypes(response.data);
            } catch (error) {
                console.error('Error fetching appointment types:', error);
            }
        };
        fetchAppointmentTypes();
    }, [data]);
    return (
        <motion.section
            className="section-large"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            id="angebot-section"
        >
            <div className="section-header">
                <div className="section-title">
                    <Typography variant="h3" component="h2" gutterBottom align="center">
                        {data.titel}
                    </Typography>
                </div>
            </div>
            <div className="section-body">
                <Container maxWidth="lg">
                    <Container maxWidth="md" className="text-centered" sx={{marginBottom:'5rem'}}>
                        <Typography color="text.primary" variant="body1" >
                            {data.hintTitle}
                        </Typography>
                        <Typography color="text.secondary" variant="body1" paragraph>
                            {data.hintText}
                        </Typography>
                        <Typography color="text.secondary" variant="body1" paragraph>
                            {data.hintText2}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.bezahlung}
                        </Typography>
                    </Container>
                    <Grid container>
                        {data.services && appointmentTypes != null && data.services.map((item, index) => {
                            const angebot = appointmentTypes.find(x => x.id == item.id);
                            if (!angebot) return null;
                            return (
                                <Grid item key={index} xs={12} md={6} lg={4}>
                                    <Angebot
                                        title={angebot.name}
                                        image={item.image}
                                        description={angebot.description}
                                        pricedescription={angebot.price}
                                        durationdescription={angebot.duration ? `(minimale Dauer: ${angebot.duration.replace(/^0(\d):00:00$/, '$1:00 Stunde/n')})` : '-'}
                                        appointmentType={angebot.name}
                                        buchbar={angebot.buchbar}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Container maxWidth="md" className="text-centered">
                        <Typography variant="h4" component="h3" gutterBottom align="center">
                            {data.ablvtitle}
                        </Typography>
                        <Typography color="text.secondary" variant="body1" paragraph align="justify">
                            {data.ableitendeVerfahrenText1}
                        </Typography>
                    </Container>

                    <Grid container>
                        {data.ableitverfahren && appointmentTypes != null && data.ableitverfahren.map((item, index) => {
                            const angebot = appointmentTypes.filter(x => x.id == item.id)[0];
                            return (
                                <Grid item key={index} xs={12} md={6} lg={4}>
                                    <Angebot
                                        title={angebot.name}
                                        image={item.image}
                                        description={angebot.description}
                                        pricedescription={angebot.price}
                                        durationdescription={angebot.duration ? `(minimale Dauer: ${angebot.duration.replace(/^0(\d):00:00$/, '$1:00 Stunde/n')})` : '-'}
                                        appointmentType={angebot.name}
                                        buchbar={angebot.buchbar}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Container maxWidth="md" className="text-centered">
                        <Image src={data.emrImage} width={1000} height={1000} alt="Logo Gesundheit Ganzheitlich" />
                        <Typography color="text.secondary" variant="body1" paragraph align="justify">
                            {data.zsr}
                        </Typography>
                        <Typography color="text.secondary" variant="body1" paragraph align="justify">
                            {data.meetinginformation}
                        </Typography>
                        <Typography color="text.secondary" variant="body1" paragraph align="justify">
                            {data.supplementsinformation}
                        </Typography>
                    </Container>


                </Container>
            </div>
        </motion.section>
    );
};
