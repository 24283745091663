'use client'

import React from "react";
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import Image from "next/image";

export const HeaderSection = ({ data }) => {

  return (
    <motion.section
      className="header-section"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 5rem)', marginTop: '3rem', marginBottom: '3rem' }}
      id="header-section"
    >
      <Box sx={{ width: 'fit-content', height: 'fit-content', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '1rem' }}>
        <Image src={data.logo} width={1000} height={1000} alt="Logo Gesundheit Ganzheitlich"/>
        <Box sx={{ marginTop: '1rem' }}>
          <Typography variant="h3" component="h1" align="justify">
            {data.description}
          </Typography>
        </Box>
      </Box>
    </motion.section>
  );
};
